import { Br, Cut, Line, Printer, Text, Row, render } from "react-thermal-printer";
// import UserReceipt from "./UserReceipt";
import { useSearchParams } from "react-router-dom";
// import { SerialPort } from "serialport";
//http://localhost:3000/?order_date=2024.08.26%2014:13:00&rec_name=%EC%95%88%EC%A2%85%EA%B8%B8&rec_phone=010-5129-7377&prod_fname=%ED%99%8D%EC%82%BC%EC%A0%95%20%EC%97%90%EB%B8%8C%EB%A6%AC%ED%83%80%EC%9E%84%2050%ED%8F%AC&total_qty_desc=1&buy_name=%EC%95%88%EC%8A%B9%EC%88%98&buy_phone=010-5116-3268
const VenderOrderList = (props) => {
  /*BUYER
_INFO : 주문자 정보
_PHONE_MOBILE : 주문자 연락처
ODID : 정몰 주문번호
ORDER_DATE_FORMAT : 정몰 주문시각
    */

  /*RECEIVER
_ADDR1 : 배송주소 
_ADDR2 : 배송주소 상세
_NAME : 수령자 정보
_PHONE_MOBILE : 수령자 연락처
    */

  /*
COMPANY_TEL : 매장 전화번호
PROD_FNAME : 주문 제품 정보
TOTAL_QTY_DESC : 주문 제품 수량
    */
  //   const buyer = props.BUYER_INFO;
  const [searchParams] = useSearchParams();
  const queryList = [...searchParams];
  const data = new Object();
  for (const [id, value] of queryList) {
    data[id] = value;
  }
  const receipt = (
    <Printer type="epson" width={42} characterSet="korea">
      <Text align="center" bold={true} size={{ width: 2, height: 2 }}>
        KGC 인삼공사(정관장)
      </Text>
      <Row left="충북 영동점" right="341-64-00539 안종길"></Row>
      <Row left="충북 영동군 영동읍 계산로 55-1" right="TEL: 043-744-2304"></Row>
      <Br />
      <Text align="left">[일시]{data.orderDate}</Text>
      <Line character="=" />
      <Text align="left">보내시는 분 : {data.buyName}님</Text>
      <Text align="right">연락처 : {data.buyPhone}</Text>
      <Br />
      <Text align="center">
        주문하신 제품: {data.prodName} - {data.prodQty}개
      </Text>
      <Br />
      <Line character="=" />
      <Text align="left">받으시는 분 : {data.recName}님</Text>
      <Text>
        받으실 주소 : {data.recAddr1} {data.recAddr2}
      </Text>
      <Text align="right">연락처 : {data.recPhone}</Text>
      <Br />
      <Cut />
    </Printer>
  );
  const onClickPrintHandler = async () => {
    const data = await render(receipt);
    console.log(data);
    // console.log(SerialPort.list());
    const port = await navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });
    console.log(port);
    const writer = port.writable?.getWriter();
    if (writer !== null) {
      await writer.write(data);
      await writer.releaseLock();
    }
    await port.close({ baudRate: 9600 });
  };

  return (
    <div>
      <div>{receipt}</div>
      <button
        color="red"
        onClick={async () => {
          await onClickPrintHandler();
        }}
      >
        {" "}
        출력하기{" "}
      </button>
    </div>
  );
};
export default VenderOrderList;
